
import React from "react";
import "../App.css";
import AgileMindsetSection from "./expanded/agileMindsetSection";
import OpenSourceTechSection from "./expanded/openSourceTechSection";
import DontJumpInSection from "./expanded/dontJumpInSection";

function expandedSection() {
  return (
        <React.Fragment>
            <AgileMindsetSection/>
            <OpenSourceTechSection/>
            <DontJumpInSection/>
        </React.Fragment>
    );
}

export default expandedSection;