import React from "react";
import "../../App.css";

import mainHeroHeatMapImg from "../../images/svg/main_image_heatmap.svg";

function heatMapOnHero() {
  return <img src={mainHeroHeatMapImg} class={"hero__image_svg"} alt="Landing page image" />;
}

export default heatMapOnHero;
