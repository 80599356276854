import React from "react";
import "../App.css";
import Heatmap from "./svgLoader/heatMapOnHero";

function hero() {
  return (
    <div class="hero">
      <div class="hero__overlay hero__overlay--gradient"></div>
      <div class="hero__mask"></div>
      <div class="hero__inner">
        <div class="container">
          <div class="hero__content">
            <div class="hero__content__inner" id="navConverter">
              <h1 class="hero__title">
                 A Digital Transformation Company
              </h1>
              <h2 class="">
                We combine user experience, technology and data to create smart
                applications for your business
              </h2>
            </div>
            <div class="hero__image__inner">
              <Heatmap/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default hero;
