import React from "react";
import "../../App.css";

import mentorImg from "../../images/svg/mentor.svg";

function mentor() {
  return <img src={mentorImg} class={"expanded__image"} alt="Mentor Mentee" />;
}

export default mentor;
