import React from "react";
import "../App.css";
import Development from "./svgLoader/development";
import Uxdesign from "./svgLoader/uxdesign";
import SoftwareAsService from "./svgLoader/softwareAsService";
import CloudHosting from "./svgLoader/cloudHosting";
import Testing from "./svgLoader/testing";
import MeetTheTeam from "./svgLoader/meetTheTeam";
import { Link } from "react-router-dom";

function whatWeDo() {
  return (
    <div class="steps landing__section">
      <div class="container">
        <h2>what we do</h2>
        <p>
          we guide SMEs towards process automation, insights and efficiency
          through digital transformation
        </p>
      </div>
      <div class="container">
        <div class="steps__inner">
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <Development />
              </div>
            </div>
            <h2>software development</h2>
            <p class="step__text">
              We develop software that helps your business thrive. Our
              developers strictly follow agile methodologies and test driven
              development to develop high quality and low cost applications .
            </p>
            <Link className={"button"} to="/software-development">
              More...
            </Link>
          </div>
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <Uxdesign />
              </div>
            </div>
            <h2>UX/UI design</h2>
            <p class="step__text">
              Our UX and UI team takes comprehensive and holistic approach to
              design wireframs, prototypes and user interfaces that are intutive and build a consistent
              user experience.
            </p>
            <Link className={"button"} to="/ui-ux-design">
              More...
            </Link>
          </div>
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <SoftwareAsService />
              </div>
            </div>
            <h2>software as a service</h2>
            <p class="step__text">
              we provide intuitive connect to and use cloud-based applications over the
              internet. This will save your capital investment and use it with
              pay as you go model. See our Mentor Plus app.
            </p>
            <Link className={"button"} to="/software-as-a-service">
              More...
            </Link>
          </div>
        </div>
        <div class="steps__inner border-on">
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <CloudHosting />
              </div>
            </div>
            <h2>lift and shift</h2>
            <p class="step__text">Move your legacy applications to cloud</p>
            <Link className={"button"} to="/moving-to-cloud">
              More...
            </Link>
          </div>
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <Testing />
              </div>
            </div>
            <h2>software testing</h2>
            <p class="step__text">
              We help your product in automation testing, QA and user acceptance
              testing
            </p>
            <Link className={"button"} to="/software-testing-qa">
              More...
            </Link>
          </div>
          <div class="step">
            <div class="step__media">
              <div class="step__image">
                <MeetTheTeam />
              </div>
            </div>
            <h2>talent management</h2>
            <p class="step__text">
              We give access to our best resources and teams, when you need it
            </p>
            <Link className={"button"} to="/talent-management">
              More...
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default whatWeDo;
