import React from "react";
import "../App.css";
import { Link } from 'react-router-dom';

function getStartedNow() {
  return (
    <div class="cta cta--reverse">
      <h3 class="cta__title">Digitalisation As a Service</h3>
      <h4 class="cta__title">The approach organizations take towards digital transformation will impact their success or failure</h4>
      <div class="container">
        <div class="cta__inner">
          <h2 class="cta__title">press the green button</h2>
          <Link to="/contactus" className={"button button__accent"}>Contact Us</Link>
        </div>
      </div>
    </div>
  );
}

export default getStartedNow;
