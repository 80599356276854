import React from "react";
import "../../App.css";

import contactUsImg from "../../images/svg/contact_us.svg";

function contactUs() {
  return <img src={contactUsImg} class={"expanded__image"} alt="Contact Us" />;
}

export default contactUs;
