import React from "react";
import "../App.css";

import Uxdesign from "./svgLoader/uxdesign";

function uiUxDesigntComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <Uxdesign />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">User Experience Design</h2>
            <p>
            <div className={"expanded__content_justified"}>
            A good intuitive design is a good business.
            All the designs are done based on organisational culture and level of customer and employee expertise.
            Foreseeing these needs involves time spent interacting with the people using the application in order to understand their challenges, frustrations, goals, and motivations.
            <br></br>We consider a software application is successful only when it benifits the employees and their customers.
              </div>
              <h3>Things we do</h3>
              <ul>
                <li>user research</li>
                <li>contextual enquiry</li>
                <li>competitive analysis</li>
                <li>wireframing &amp; prototyping</li>
                <li>usability testing - moderated</li>
                <li>visual designing</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default uiUxDesigntComponent1;
