import React from "react";
import "../App.css";

import Uxdesign from "./svgLoader/meetTheTeam";

function talentManagementComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <Uxdesign />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Talent Management</h2>
            <p>
              <div className={"expanded__content_justified"}>
                We put right talent in right place at right time and are globally scaled &amp; locally relevant. 
                Hiring talented individuals come with lots of complexity and
                ambiguity. We are industry experts with purpose-driven,
                performance-oriented, and principles-led company. Talented work
                resourses are directly proportional to company's success. We
                provide you with value added talented individuals who performs
                exceptionally well. <br></br>When we think about a software product we
                consider customer-first approach in the similar context when we
                consider employees we follow business-first mind set.
                We focus on targeted development of our individuals based on changing market demands.
              </div>
              <h3>Why hire from us</h3>
              <ul>
                <li>Rapid allocatoin</li>
                <li>simple pay as you go model</li>
                <li>diverse technology expertise</li>
                <li>your company need not have strong HR team to get best talent</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default talentManagementComponent1;
