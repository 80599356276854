import React from "react";
import "../../App.css";

import sassImg from "../../images/svg/sass.svg";

function softwareAsService() {
  return <img src={sassImg} class={"step__image"} alt="UX UI Design" />;
}

export default softwareAsService;
