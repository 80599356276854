import React from "react";
import "../App.css";

import ContactUs from "./svgLoader/contactUs";

function contactus() {
  return (
    <div class="auth">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <ContactUs />
          </div>
          <div class="auth__auth">
            <h1 class="auth__title">Start digital transformatoin now</h1>
            <p>We will be very happy to get in touch with you</p>
            <form
              method="post"
              action="https://formspree.io/mnqdkrzd"
              autocompelete="new-password"
              role="presentation"
              class="form"
            >
              <label>Name</label>
              <input
                required
                type="text"
                name="name"
                id="person_company_name"
                placeholder=""
              />
              <label>Email</label>
              <input
                required
                type="email"
                name="_replyto"
                id="email"
                placeholder="you@company.com"
              />
              <label for="textarea">Message</label>
              <textarea
                placeholder="How can we help?"
                name="message"
                id="textarea"
                maxlength="1000"
              ></textarea>
              <button type="submit" class="button button__primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default contactus;
