import React from "react";
import "../../App.css";
import userExperienceImg from "../../images/svg/user_experience.svg";

function uxdesign() {
  return <img src={userExperienceImg} class={"step__image"} alt="UX UI Design" />;
}

export default uxdesign;


