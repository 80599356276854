import React from "react";
import "../App.css";

import AgileMindset from "./svgLoader/agileMindset";

function softwareDevelopmentComponent2() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="expanded__content">
            <h2 class="expanded__title">Agile Mindset</h2>
            <p class="expanded__text">
            <div className={"expanded__content_justified"}>
              All our teams move with this mindset. <br></br>Agile Mindset is more
              about attitude and viewpoints.
              </div>
              <h4>These include</h4>
              <ul>
                <li>Respect</li>
                <li>Collaboration</li>
                <li>Improvement Cycle </li>
                <li>Learning Cycle </li>
                <li>Pride in Ownership </li>
                <li>Focus on Delivering Value</li>
                <li>Ability to Adapt to Change</li>
              </ul>
              It is pragmatic and action-oriented more than a theoretical
              philosophy. Agile of course is more than mindset as it includes
              different values, principles, and practices but waht is important
              is the human intelligence, the sensibility, and the values that
              are driving those processes, practices, and systems.
            </p>
          </div>
        <div class="auth__media">
            <AgileMindset />
          </div>
        </div>
      </div>
    </div>
  );
}

export default softwareDevelopmentComponent2;
