import React from "react";
import "../App.css";

import OpenSource from "./svgLoader/openSourceTech";

function softwareDevelopmentComponent3() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <OpenSource />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Using Open Source Technologies</h2>
            <p class="expanded__text">
            <div className={"expanded__content_justified"}>
              Build a better business with opensource technologies.
            The blend of opensource technologies and microservices will speed up go-to-market.
            Now a days many large scale organisations rely on the likes of Linux, Hadoop, Kubernetes and TensorFlow which are all opensources.
            </div>
              <h4>The advantages of opensource are</h4>
              <ul>
                <li>high quality software</li>
                <li>security and transparency</li>
                <li>community</li>
                <li>coft effective</li>
                <li>the wisdom of the crowd</li>
                <li>no fixed contracts</li>
                <li>extensive customization</li>
                <li>easy to audit</li>
              </ul>

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default softwareDevelopmentComponent3;
