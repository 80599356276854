import React from "react";
import "../App.css";

import Testing from "./svgLoader/testing";

function softwareTestingComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <Testing />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Software Testing</h2>
            <p>
            <div className={"expanded__content_justified"}>
            There is no software which will be released without testing. 
            We can off load testing process and provide professional services.
            Our professional testers are International Software Testing Qualifications Board(ISTQB) certified.
            </div>
              <h3>Things we do</h3>
              <ul>
                <li>functional Testing</li>
                <li>test automation</li>
                <li>user acceptance testing</li>
                <li>performance Testing</li>
                <li>usability testing</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default softwareTestingComponent1;
