import React from "react";
import "../App.css";

import CloudHosting from "./svgLoader/cloudHosting";

function liftAndShiftComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <CloudHosting />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Moving to cloud</h2>
            <p>
              <div className={"expanded__content_justified"}>
                Lift and shift is the fastest and cost effective way to move
                from on-premises to cloud. We offer monolithic applications to
                be migrated safely and securely. The biggest challenge comes in
                data migratoin. we offer different ways of data migration based
                on data sensitivity. With this approach it will be minimal
                disruption to operations of the company.
                <br></br>More stratrgic way is to go with hybrid cloud
                approach. In this approach first we can move best and easily
                moveable services to cloud.
                <h3> Advantages of moving to cloud</h3>
                <ul>
                  <li>
                    reduced on-premises data center costs and operations
                    overhead
                  </li>
                  <li>improved performance</li>
                  <li>on demand scaling</li>
                  <li>cost effective</li>
                  <li>enhanced security</li>
                  <li>use in-build cloud softwares</li>
                  <li>more agile releases</li>
                </ul>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default liftAndShiftComponent1;
