import React from "react";
import "../App.css";

function footer() {
  return (
    <div class="footer footer--dark">
      <div class="container">
        <div class="footer__inner">
          <div>
            <a href="/" class="footer__textLogo">
              email<br></br>
              <sup>
                <a
                  href="mailto:go.digital@manchiapps.co.uk?Subject=Go%20Digital"
                  target="_top"
                >
                  go.digital@manchiapps.co.uk
                </a>
              </sup>
            </a>
          </div>
          <div class="">
          <a href="/" class="footer__textLogo">
            manchi Apps<br></br>
            <sup>
              ManchiApps Ltd is a company registered in England and Wales.{" "}
              <br></br>
              Registered number: 08589533
            </sup>
          </a>
        </div>
        </div>
      </div>
    </div>
  );
}

export default footer;
