import React from "react";
import "../../App.css";

import cloudHostingImg from "../../images/svg/cloud_hosting.svg";

function cloudHosting() {
  return <img src={cloudHostingImg} class={"step__image"} alt="Cloud hosting" />;
}

export default cloudHosting;
