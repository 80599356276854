import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import TalentManagementComponent1 from "../components/talentManagementComponent1";
import GetStartedNow from "../components/getStartedNow";

function talentManagement() {
  return (
    <React.Fragment>
      <Navbar />
      <TalentManagementComponent1 />
      <GetStartedNow />
      <Footer />
    </React.Fragment>
  );
}

export default talentManagement;
