import React from "react";
import "../../App.css";

import testingImg from "../../images/svg/testing_done.svg";

function testing() {
  return <img src={testingImg} class={"step__image"} alt="Testing QA" />;
}

export default testing;
