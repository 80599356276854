import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "../App";

import ContactUs from "../pages/contact";
import Sass from "../pages/sass";
import UiUxdesign from "../pages/uiUxdesign";
import SoftwareDevelopment from "../pages/softwareDevelopment";
import LiftAndShift from "../pages/liftAndShift";
import SoftwareTesting from "../pages/softwareTesting";
import TalentManagement from "../pages/talentManagement";
import ScrollToTop from "../config/scrollToTop";

export default function RouteConfig() {
  //Team goals Shared workspace  Positive attitude Remote team
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route eaxct path="/talent-management" component={TalentManagement} />
          <Route eaxct path="/moving-to-cloud" component={LiftAndShift} />
          <Route
            eaxct
            path="/software-testing-qa"
            component={SoftwareTesting}
          />
          <Route eaxct path="/software-as-a-service" component={Sass} />
          <Route
            eaxct
            path="/software-development"
            component={SoftwareDevelopment}
          />
          <Route eaxct path="/ui-ux-design" component={UiUxdesign} />
          <Route eaxct path="/contactus" component={ContactUs} />
          <Route path="/" component={App} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
