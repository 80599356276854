import React from "react";
import "../App.css";

import Mentor from "./svgLoader/mentor";

function uiUxDesigntComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <Mentor />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Mentor Plus</h2>
            <p>
              <div className={"expanded__content_justified"}>
                Mentor Plus is a cloud native software as a service application
                which bridges the gap between mentor and mentee. There are
                hundreds of trainings going on but still there is a very big
                skills gap in the market. Many people are taking lot of
                classroom or online courses and have tremendrous amount of
                knowledge but its not being transformed into right practice.
                Mentor Plus will provide a platform to transform the learning
                into pratical practice. This platform is secure, reliable and
                configurable.
              </div>
              <h3>mentor can</h3>
              <ul>
                <li>
                  build a customised guideline for mentee’s professional growth
                </li>
                <li>set up plan and action it</li>
                <li>provide milestones, guidance, comments</li>
                <li>Evaluate plans and decisions</li>
                <li>
                  Help your mentee identify problems and guide them towards
                  solutions
                </li>
                <li>support until mentee’s goals are achivied</li>
              </ul>
              <h3>mentee can</h3>
              <ul>
                <li>search for mentors</li>
                <li>send request for professional help</li>
                <li>seek guidance and advice for professional development</li>
                <li>
                  follow the action plan and carry out tasks by agreed times
                </li>
                <li>logbook of activities</li>
                <li>
                  check progress, guidance, comments,assessment,evaluation
                </li>
              </ul>
              <h3>moderator can</h3>
              <ul>
                <li>is optional but good to have</li>
                <li>agreed by both mentor and mentee</li>
                <li>track progress, comment, evaluate</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default uiUxDesigntComponent1;
