import React from "react";
import "../../App.css";

import agileImg from "../../images/svg/agile.svg";

function agileMindset() {
  return <img src={agileImg} class={"expanded__image"} alt="Agile Mindset" />;
}

export default agileMindset;
