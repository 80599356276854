import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Hero from "../components/hero";
import WhatWeDo  from '../components/whatWeDo';
import Footer  from '../components/footer';
import GetStartedNow from "../components/getStartedNow";
import ExpandedSection from "../components/expandedSection";

function landing() {
  return (
    <React.Fragment>
      <Navbar />
      <Hero />
      <WhatWeDo />
      <ExpandedSection/>
      <GetStartedNow/>
      <Footer/>
    </React.Fragment>
  );
}

export default landing;
