
import React from "react";
import "../../App.css";
import GoalsTech from "../svgLoader/dontJumpIn";

function dontJumpInSection() {
  return (
  <div class="expanded landing__section">
    <div class="container">
      <div class="expanded__inner">
        <div class="expanded__media">
          <GoalsTech/>
        </div>
        <div class="expanded__content">
          <h2 class="expanded__title">Don't jump straight in</h2>
          <p class="expanded__text">Don't jump straight in. Take a stratergic and wise decision. We help you to build rapid prototyping.
            These are very cost effective way of starting a project. 
            Then we make MVP(minimum viable product) so that it starts you journey of Digital Transformation. 
            After that when your company is adapted to the product we can enhance the product for the needs and growth of your company.
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default dontJumpInSection;