import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SassComponent1 from "../components/sassComponent1";
import GetStartedNow from "../components/getStartedNow";

function sass() {
  return (
    <React.Fragment>
      <Navbar />
      <SassComponent1 />
      <GetStartedNow />
      <Footer />
    </React.Fragment>
  );
}

export default sass;
