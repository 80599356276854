import React from "react";
import "../App.css";

import DeveloperWorking from "./svgLoader/developerWorking";

function softwareDevelopmentComponent1() {
  return (
    <div class="expanded landing__section">
      <div class="container">
        <div class="auth__inner">
          <div class="auth__media">
            <DeveloperWorking />
          </div>
          <div class="expanded__content">
            <h2 class="expanded__title">Software Development</h2>
            <p>
            <div className={"expanded__content_justified"}>
              Our approach to software development is focused towards the
              digitalization of a company rather than just implement the
              technology. We mainly develop with cloud native approach.
              Developing high quality softwares is the core of our company. We
              don&apos;t compete with our competitors rather than we put all our
              energy to understand our clients requirement and develop what is
              actually needed.
              </div>
              <h3>Things we do</h3>
              <ul>
                <li>end to end application development</li>
                <li>software consulting</li>
                <li>integration with other softwares or payment systems</li>
                <li>software development outsourcing</li>
                <li>transform monolithic to microservices</li>
                <li>software maintenance and support</li>
                <li>
                  writing business driven tests(bdd) for legacy applications
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default softwareDevelopmentComponent1;
