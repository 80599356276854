import React from "react";
import "../../App.css";

import goalsImg from "../../images/svg/goals.svg";

function dontJumoIn() {
  return <img src={goalsImg} class={"expanded__image"} alt="Prototype" />;
}

export default dontJumoIn;
