import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ContactUs from "../components/contactus";

function contactUs() {
  return (
    <React.Fragment>
      <Navbar />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}

export default contactUs;
