import React from "react";
import "../App.css";
import MobileNavbar from "./mobileNavbar";
import { Link } from "react-router-dom";

function navbar() {
  return (
    <div class="navbar navbar--extended">
      <nav className="nav__mobile"></nav>
      <div className="container">
        <div class="navbar__inner">
          <a href="/" className="navbar__logo">
            manchi Apps
          </a><div>BETA</div>

          <nav className="navbar__menu">
            <ul>
              <li>
                <Link to="/contactus">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <MobileNavbar />
        </div>
      </div>
    </div>
  );
}

export default navbar;
