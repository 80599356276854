import React from "react";
import "../../App.css";

import meetTheTeamImg from "../../images/svg/meet_the_team.svg";

function meetTheTeam() {
  return <img src={meetTheTeamImg} class={"step__image"} alt="Meet the team" />;
}

export default meetTheTeam;
