
import React from "react";
import "../../App.css";
import AgileMindset from "../svgLoader/agileMindset";

function agileMindsetSection() {
  return (
    <div class="expanded landing__section">
    <div class="container">
      <div class="expanded__inner">
        <div class="expanded__media">
          <AgileMindset/>
        </div>
        <div class="expanded__content">
          <h2 class="expanded__title">Agile Mindset</h2>
          <p class="expanded__text">Agile Mindset is more about attitude and viewpoints. These include Respect, Collaboration, Improvement Cycle, Learning Cycle, Pride in Ownership, Focus on Delivering Value,  Ability to Adapt to Change.
            It is pragmatic and action-oriented more than a theoretical philosophy. Agile of course is more than mindset as it includes different values,
            principles, and practices but waht is important is the human intelligence, the sensibility, and the values that are driving those processes, practices, and systems.
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default agileMindsetSection;