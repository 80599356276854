import React from "react";
import "../../App.css";

import openSourceImg from "../../images/svg/open_source.svg";

function openSource() {
  return <img src={openSourceImg} class={"expanded__image"} alt="Open sources technologies" />;
}

export default openSource;
