
import React from "react";
import "../../App.css";
import OpenSourceTech from "../svgLoader/openSourceTech";

function openSourceTechSection() {
  return (
  <div class="expanded landing__section">
    <div class="container">
      <div class="expanded__inner">
        <div class="expanded__media">
          <OpenSourceTech/>
        </div>
        <div class="expanded__content">
          <h2 class="expanded__title">Open source technologies</h2>
          <p class="expanded__text">As many big companies are open sourcing their softwares why not utilize already developed, tested and ever evolving open sourced products.
            With the help of microservices we can easily plug and play with these technologies.
            We can help you using open source technoligies and make customised products.
            Its very cost effective way to transform you business for you digital strategy.
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default openSourceTechSection;