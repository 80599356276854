import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import UiUxDesigntComponent1 from "../components/uiUxDesigntComponent1";
import UiUxDesigntComponent2 from "../components/uiUxDesigntComponent2";
import GetStartedNow from "../components/getStartedNow";

function uiUxdesign() {
  return (
    <React.Fragment>
      <Navbar />
      <UiUxDesigntComponent1 />
      <GetStartedNow />
      <Footer />
    </React.Fragment>
  );
}

export default uiUxdesign;
