import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SoftwareDevelopmentComponent1 from "../components/softwareDevelopmentComponent1";
import SoftwareDevelopmentComponent2 from "../components/softwareDevelopmentComponent2";
import SoftwareDevelopmentComponent3 from "../components/softwareDevelopmentComponent3";
import GetStartedNow from "../components/getStartedNow";

function softwareDevelopment() {
  return (
    <React.Fragment>
      <Navbar />
      <SoftwareDevelopmentComponent1 />
      <SoftwareDevelopmentComponent2 />
      <SoftwareDevelopmentComponent3 />
      <GetStartedNow />
      <Footer />
    </React.Fragment>
  );
}

export default softwareDevelopment;
